new WOW().init();

$(document).ready(function(){
  $('input[type="tel"').inputmask('+7 (999) 999-99-99');

  $(document).on('click', '.course-block__btn', function(){
    $('.course-block').toggleClass('course-block--active');
    $(this).toggleClass('course-block__btn--no-animate');
    return false;
  });

  $(document).on('click', '.course-block__close', function(){
    $('.course-block--active').removeClass('course-block--active');
    $('.course-block__btn--no-animate').removeClass('course-block__btn--no-animate');
    return false;
  });

  $('.fancybox').fancybox({
    afterShow: function(instance, current) {
      var btn = $(instance.$trigger);
      var href = btn.attr('href');

      if(href === '#callback-popup') {
        var age = btn.data('age');

        if (age) {
          $(href + ' [name="fields[age]"]').val(age);
        }
      }
    },
    afterClose: function(instance, current) {
      var btn = $(instance.$trigger);
      var href = btn.attr('href');

      if(href === '#callback-popup') {
        $(href + ' [name="fields[age]"]').val('');
      }
    }
  });

  $(document).on('submit', '.form-course', function(){
    var link = $(this).find('[name="course-link"]').val();
    var responseBlock = $(this).find('.form__response');
    var iframeMessage = $('.course-iframe-message');
    var iframeMessageLink = $('.course-iframe-message a');

    responseBlock.text('');

    if(link.indexOf('https://events.webinar.ru/course-info/') === 0){
      $.fancybox.open({
        src: link,
        type: 'iframe',
        opts: {
          afterShow: function(){
            iframeMessage.addClass('course-iframe-message--visibility');
            iframeMessageLink.attr('href', link);
          },
          beforeClose: function(){
            $('.course-iframe-message--visibility').removeClass('course-iframe-message--visibility');
            iframeMessageLink.attr('href', '#');
          }
        }
      });
    } else {
      responseBlock.text('Введен некорректный адрес');
    }

    return false;
  });

  $(document).on('click', '.fancybox-close-btn', function(){
    $.fancybox.close();
    return false;
  });

  $(window).scroll(function(){
    var top = $(this).scrollTop();
    var documentHeight = $(document).outerHeight(true);
    var headerHeight = $('.header').outerHeight();
    var footerHeight = $('.footer').outerHeight();
    var windowHeight = $(window).height();

    if(top > 0){
      $('.header').addClass('header--shadow');
    } else {
      $('.header').removeClass('header--shadow');
    }

    if(top > headerHeight){
      $('.header').addClass('header--fixed');
      $('.mobile-menu').addClass('mobile-menu--fixed');
    } else {
      $('.header').removeClass('header--fixed');
      $('.mobile-menu').removeClass('mobile-menu--fixed');
    }

    if(top > documentHeight - footerHeight - windowHeight){
      $('.fixed-block').addClass('fixed-block--hidden');
      $('#vk_community_messages').addClass('hidden');
      $('.course-btn').addClass('course-btn--hidden');
    } else {
      $('.fixed-block--hidden').removeClass('fixed-block--hidden');
      $('#vk_community_messages.hidden').removeClass('hidden');
      $('.course-btn--hidden').removeClass('course-btn--hidden');
    }
  });

  $(document).on('submit', '.form-ajax', function(){
    var form = $(this);
    var action = form.attr('action');
    var method = form.attr('method');
    var formData = new FormData(form.get(0));
    var parentsPopup = $(this).parents('.popup-block');

    form.find('.form__response').addClass('d-none');

    $.ajax({
      url: (action) ? action : location.href,
      type: (method) ? method : 'post',
      data: formData,
      contentType: false,
      processData: false,
      success: function(data){
        var data = JSON.parse(data);

        if(data.success && parentsPopup.length === 0){
          form.find('.form__response').addClass('form__response--success');
          form.find('.form__response').text(data.text);
        } else if(data.success && parentsPopup.length > 0){
          $.fancybox.close();
          $.fancybox.open({
            src:'#callback-popup-done',
            type:'inline'
          });
        } else {
          form.find('.form__response').text(data.text);
          form.find('.form__response').removeClass('form__response--success');
        }

        if(data.success){
          ym(66626158,'reachGoal','ORDERYES');
        }

        form.find('.form__response').removeClass('d-none');
      }
    });

    return false;
  });

  $(document).on('click', '.menu__link, .mobile-menu__link', function(){
    var id = $(this).attr('href');
    var top = 0;
    var marginTop = 0;
    var windowWidth = $(window).width();
    var headerHeight = $('.header').outerHeight();
    var mobileMenuHeight = $('.mobile-menu').outerHeight();

    if(windowWidth <= 575){
      marginTop = mobileMenuHeight;
    } else {
      marginTop = headerHeight;
    }

    $('.menu').removeClass('menu--active');
    $('.wrapper').removeClass('wrapper--active');
    $('.mobile-menu__wrapper--active').removeClass('mobile-menu__wrapper--active');
    $('.burger--active').toggleClass('burger--active');

    if($(id).length > 0){
      var top =  $(id).offset().top - marginTop - 15;
  		$('html, body').animate({ scrollTop: top }, 500);
    }

    return false;
  });

  $(document).on('click', '.mobile-btn', function(){
    $('.menu').toggleClass('menu--active');
    $('.wrapper').toggleClass('wrapper--active');
    $('.mobile-menu__wrapper').toggleClass('mobile-menu__wrapper--active');
    $(this).toggleClass('burger--active');

    return false;
  });

  $(document).on('click', '.wrapper--active', function(){
    $('.menu').removeClass('menu--active');
    $('.wrapper').removeClass('wrapper--active');
    $('.mobile-menu__wrapper--active').removeClass('mobile-menu__wrapper--active');
    $('.burger--active').toggleClass('burger--active');

    return false;
  });

  $(document).on('click', '.slider-dot', function(){
    var sliderId = $(this).data('slider-id');
    var number = $(this).data('number');
    var classActive = '';

    if($(this).hasClass('block-content')){
      classActive = 'block-content--active';
    } else if($(this).hasClass('slider-dots__item')){
      classActive = 'slider-dots__item--active';
    }

    if(classActive){
      $('.' + classActive + '[data-slider-id="' + sliderId + '"]').removeClass(classActive);
      $(this).addClass(classActive);
    }

    $(sliderId).trigger('to.owl.carousel', number);
  });

  $(document).on('click', '.tab-link', function(){
    var blockId = $(this).data('block-id');
    var number = $(this).data('number');

    $(blockId + ' .tabs-content--active').removeClass('tabs-content--active');
    $(blockId + '-' + number).addClass('tabs-content--active');

    $('.slider-dots__item--active[data-block-id="' + blockId + '"]').removeClass('slider-dots__item--active');
    $(this).addClass('slider-dots__item--active');
  });

  $('.owl-carousel').on('changed.owl.carousel', function(event) {
    var element = event.target;    
    var sliderId = $(element).attr('id');
    var number = event.item.index;

    if(sliderId){
      $('.slider-dot[data-slider-id="#' + sliderId + '"][data-number="' + number + '"]').click();
    }
  });

  $('.slider-block-2').owlCarousel({
    margin: 30,
    loop: false,
    items: 1,
    responsive: {
      '1200': {
        nav: false,
        dots: false
      },
      '576': {
        nav: true,
        dots: false
      },
      '320': {
        nav: false,
        dots: true
      }
    }
  });

  $('.slider-block-3').owlCarousel({
    margin: 30,
    loop: false,
    items: 1,
    responsive: {
      '576': {
        nav: true,
        dots: false
      },
      '320': {
        nav: false,
        dots: true
      }
    }
  });

  $('.slider-block-7').owlCarousel({
    margin: 30,
    loop: true,
    responsive: {
      '1200': {
        nav: true,
        dots: false,
        items: 2
      },
      '576': {
        nav: true,
        dots: false,
        items: 1,
      },
      '320': {
        nav: false,
        dots: true,
        items: 1
      }
    }
  });

  $('.slider-block-8').owlCarousel({
    margin: 30,
    loop: true,
    items: 1,
    responsive: {
      '576': {
        nav: true,
        dots: false,
      },
      '320': {
        nav: false,
        dots: true
      }
    }
  });

  $('.slider-block-picture').owlCarousel({
    margin: 30,
    loop: false,
    items: 6,
    responsive: {
      '1200': {
        items: 6,
        nav: true,
        dots: false,
      },
      '992': {
        items: 5,
        nav: true,
        dots: false,
      },
      '768': {
        items: 4,
        nav: true,
        dots: false,
      },
      '576': {
        items: 3,
        nav: true,
        dots: false,
      },
      '320': {
        items: 2,
        nav: false,
        dots: true
      }
    }
  });
});